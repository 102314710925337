import React from "react"
import Page from "../../../components/page"
import ResponsiveImageGrid from "../../../components/responsive-image-grid"
import { themes } from "../../../styles/themes"
import paths from "../../../utils/paths"

const pleasureEmpireGrid = labels => [
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "grid",
        orientation: "vertical",
        weight: 49.25,
        grid: [
          {
            type: "image",
            weight: 49,
            src: "/images/pleasure-empire/madame-chaise-lounge.jpg",
            title: labels.pleasureEmpireMadameTitle,
            subtitle: labels.pleasureEmpireMadameChaiseLoungeSubtitle,
            alt: labels.pleasureEmpireMadameChaiseLoungeAlt,
            imgTitle: labels.madameImgTitle
          },
          {
            type: "image",
            weight: 49,
            src: "/images/pleasure-empire/mistery-lounge.jpg",
            title: labels.pleasureEmpireMisteryLoungeTitle,
            alt: labels.pleasureEmpireMisteryLoungeAlt,
            imgTitle: labels.misteryLoungeImgTitle
          }
        ]
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/pleasure-empire/boudoir-collection-gif.gif",
        title: labels.pleasureEmpireBoudoirCollectionTitle,
        aspectRatio: "793 / 1143",
        alt: labels.pleasureEmpireBoudoirCollectionAlt,
        imgTitle: `${labels.boudoirCollectionImgTitle}: ${labels.madameImgTitle}, ${labels.misteryLoungeImgTitle}`
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "image",
        weight: 49.25,
        src: "/images/pleasure-empire/boudoir-collection-detail.jpg",
        alt: labels.pleasureEmpireBoudoirCollectionConnectionAlt,
        imgTitle: `${labels.boudoirCollectionImgTitle}: ${labels.madameImgTitle}, ${labels.misteryLoungeImgTitle}`
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/pleasure-empire/moon-side-table.jpg",
        title: labels.pleasureEmpireMoonSideTableTitle,
        titleColor: "black",
        alt: labels.pleasureEmpireMoonSideTableAlt,
        imgTitle: labels.moonSideTableImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "html",
        weight: 100,
        html: (
          <div className="paragraph-div-centered-relatively pleasure-empire-freedom-text-container">
            <p>{labels.pleasureEmpireFreedomText}</p>
          </div>
        )
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "grid",
        orientation: "vertical",
        weight: 49.25,
        grid: [
          {
            type: "image",
            weight: 49,
            src: "/images/pleasure-empire/mens-secrets-armchair.jpg",
            title: labels.pleasureEmpireMensSecretsTitle,
            subtitle: labels.pleasureEmpireMensSecretsLeatherArmchairSubtitle,
            alt: labels.pleasureEmpireMensSecretsLeatherArmchairAlt,
            imgTitle: labels.mensSecretsImgTitle
          },
          {
            type: "image",
            weight: 49,
            src: "/images/pleasure-empire/mens-secrets-armchair-tabouret.jpg",
            title: labels.pleasureEmpireMensSecretsTitle,
            subtitle:
              labels.pleasureEmpireMensSecretsLeatherArmchairTabouretSubtitle,
            alt: labels.pleasureEmpireMensSecretsLeatherArmchairTabouretAlt,
            imgTitle: labels.mensSecretsImgTitle
          }
        ]
      },
      {
        type: "image",
        weight: 49.25,
        src: "/images/pleasure-empire/007-bar.gif",
        title: labels.pleasureEmpire007BarTitle,
        alt: labels.pleasureEmpire007BarAlt,
        imgTitle: labels.double07BarImgTitle
      }
    ]
  }
]

export default function PleasureEmpire({ labels, ...props }) {
  return (
    <Page
      {...props}
      labels={labels}
      title={labels.pleasureEmpire}
      theme={themes(labels).ipsBlack(true)}
      description={labels.pleasureEmpireDescription}
      keywords={labels.pleasureEmpireKeywords}
      ogUrl={paths(labels).pleasureEmpire}
      ogImage="/images/og-images/pleasure-empire.jpg"
      headerPadding
      footerPadding
    >
      <ResponsiveImageGrid grid={pleasureEmpireGrid(labels)} margin />
    </Page>
  )
}
